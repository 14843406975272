import { motion, animate } from "framer-motion"
import { useEffect } from "react"
import { Link } from "react-router-dom"
import './Home.css'
import { Analytics } from '@vercel/analytics/react';


export default function Home() {

    const OnloadText = () => {
        var sequence = [["#title1", { opacity: 1, x: 0 }, { duration: 0.5, type: "ease" }],
        ["#title2", { opacity: 1, x: 0 }, { duration: 0.5, type: "ease" }], ["#titleP", { opacity: 1, x: 0 }, { duration: 0.5, type: "ease" }],
        ["#button_section", { opacity: 1, x: 0 }, { duration: 0.5, type: "ease" }]
        ]
        animate(sequence)
    }

    useEffect(() => {
        OnloadText()
    }, [])

    return (
        <>
        <Analytics />
            <div id="group">
                <div id="left">
                    {/* <h2>@gilbert.ntoutoumeNkoghe</h2> */}
                    <section id="section_container">
                        <motion.h1 id="title1" initial={{ opacity: 0, x: -60 }}
                        ><p id="web">
                                <svg viewBox="0 0 554.37 137.45">
                                    <g className="Project">
                                        <text x="0" y="100" font-family="Arial" font-size="40" fill="black">w</text>
                                        <text x="70" y="100" font-family="Arial" font-size="40" fill="black">e</text>
                                        <text x="120" y="100" font-family="Arial" font-size="40" fill="black">b</text>
                                    </g>
                                </svg>
                            </p><br></br>DEVELOPER</motion.h1>
                        <motion.h3 id="title2" initial={{ opacity: 0, x: -60 }}
                        >web & mobile application design</motion.h3>
                        <motion.p id="titleP" initial={{ opacity: 0, x: -60 }}
                        >
                            I'm currently studying to become a digital engineer, specializing in the Internet of Things (IoT), web and mobile development, augmented reality and UX/UI design.
                        </motion.p>
                        <motion.section id="button_section" initial={{ opacity: 0, x: -60 }}>
                            <motion.div className="button"
                                whileHover={{ backgroundColor: 'rgb(89,198,177)', color: "rgb(15,12,16)" }}
                                transition={{ duration: 0.1, type: "ease" }}><Link to='/about'>contact me</Link></motion.div>
                            <motion.div className="button" whileHover={{ backgroundColor: 'rgb(89,198,177)', color: "rgb(15,12,16)" }}
                                transition={{ duration: 0.1, type: "ease" }}><Link to='/resume'>my resume</Link></motion.div>
                        </motion.section>
                    </section>
                </div>
                {/* <div id="right"></div> */}
            </div>
        </>
    )
}