
const img = require("../Media/CA.png")
const img3 = require("../Media/CA3.png")
const img2 = require("../Media/CA2.png")
const img5 = require("../Media/CA5.png")
const img6 = require("../Media/CA4.png")
const img1 = require("../Media/CA1.png")
const linkedin = require('../Media/images/in.png');
const github = require('../Media/images/github.png');
const mail = require('../Media/images/mail.png');


const CarsAugmented = () => {

    return <div style={{ margin: "0 10% 0 10%", display: 'flex', flexDirection: "column" }}>
        <h1 style={{ width: "100%", textAlign: 'center', color: "white", fontSize: '40px' }}>CARS AUGMENTED</h1>
        <br />
        <br />
        <section className="elements">
            <h3>Description du projet</h3>

            <br />
            <p>
                L'application Cars Augmented est une application innovante qui permet aux
                utilisateurs d'Android de visualiser les informations techniques des
                véhicules en réalité augmentée. Cette application a été développée en
                utilisant l'éditeur Unity, qui est un outil de développement de jeux et
                d'applications en 3D, ainsi que le plugin Vuforia, qui permet de stocker
                des images en base de données pour déclencher la superposition de modèles
                2D ou 3D sur la réalité augmentée. L'application est conçue pour être
                multi-plateforme, ce qui signifie qu'elle peut être utilisée sur plusieurs
                systèmes d'exploitation différents. De plus, elle fonctionne sur un
                serveur distant, ce qui permet aux utilisateurs de bénéficier d'une
                expérience de réalité augmentée fluide et rapide. Grâce à l'application
                Cars Augmented, les utilisateurs peuvent visualiser les caractéristiques
                techniques des véhicules, telles que la puissance du moteur, la
                consommation de carburant et les dimensions, en utilisant la réalité
                augmentée. Pour cela, l'application utilise des images stockées en base de
                données, qui sont associées à des modèles 2D ou 3D créés à l'aide des
                outils UI de Unity.
            </p>
        </section>
        <br />
        <br />
        <section className="elements">
            <h3>Description detaillé et fonctionnement</h3>

            <br />
            <p>
                Le projet en question utilise un canvas pour le rendu, ce qui permet
                d'afficher les éléments graphiques à l'écran. Pour le background, une
                image blanche a été utilisée et intégrée dans un élément image. Les
                modèles 3D utilisés pour le projet sont d'extension ".obj" et ont été
                trouvés sur le site
                <a href="https://www.cgtrader.com/fr/libre-3d-modeles/voiture"> https://www.cgtrader.com/fr/libre-3d-modeles/voiture</a>. Pour la génération
                d'images, le projet a utilisé un élément UI de Unity appelé "RawImage".
                Cet élément permet d'afficher des images non compressées directement dans
                l'interface utilisateur de l'application. En utilisant cet élément, le
                projet a pu afficher les images générées en superposition avec les modèles
                3D de voitures pour une expérience utilisateur plus immersive.
            </p>
            <section className="screenShotList_CA">
                <img className="CA_img" alt="screenshot1" src={img} />
                <img className="CA_img" alt="screenshot1" src={img5} />
                <img className="CA_img" alt="screenshot1" src={img6} />
            </section>
            <br />
            <br />
            <p>
                La hiérarchie du projet est organisée de la manière suivante : l'ARCamera
                est définie au plus haut niveau et contient 3 images target. Lorsque l'une
                de ces images target est détectée, cela déclenche l'apparition du canvas
                qui contient les éléments UI tels que le texte, la rawimage et les modèles
                3D importés, visibles en bleu dans la hiérarchie. Il est très important de
                choisir la bonne image target pour la détection, car Vuforia ne prend en
                charge que les images de moins de 24 bits. De plus, il faut respecter le
                format et la profondeur de couleur de l'image, car cela affecte la façon
                dont elle est interprétée par le système. Pour évaluer la qualité des
                images, le système attribue un score à chaque image. Les images avec le
                meilleur score seront les plus facilement détectées par l'ARCamera, il est
                donc recommandé de privilégier ces images. En somme, la hiérarchie du
                projet est organisée autour de l'ARCamera et des images target, qui sont
                utilisées pour déclencher l'apparition des éléments UI tels que le texte,
                la rawimage et les modèles 3D. Il est crucial de choisir la bonne image
                target pour garantir une détection précise et fiable par l'ARCamera, en
                respectant les formats et la profondeur de couleur pris en charge par
                Vuforia.
            </p>
            <section className="screenShotList_CA">
                <img className="CA_img" alt="screenshot1" src={img1} />
            </section>
            <br />
            <br />
            <p>
                L'installation de l'application se fait en scannant un code QR, qui est
                généralement fourni avec le produit ou disponible sur le site web de
                l'application. Ce code QR permet d'accéder à une page web qui contient un
                lien de téléchargement de l'application (dans ce cas précis, l'application
                Cars Augmented). Une fois que l'utilisateur a accédé à la page web via le
                code QR, il peut télécharger l'application directement sur son mobile en
                quelques mégaoctets (environ 180 Mo dans ce cas). Il suffit de suivre les
                instructions d'installation pour installer l'application sur le téléphone.
            </p>
            <section className="screenShotList_CA">
                <img className="CA_img" alt="screenshot1" src={img3} />
                <img className="CA_img" alt="screenshot1" src={img2} />

            </section>
        </section>
        <br />
        <br />
        <section className="elements">
            <h3>Perspectives et futures amélioration</h3>

            <br />
            <p>
                Ce projet est un projet scolaire qui m'a été confié dans le but
                d'apprendre et de me familiariser avec Unity et Vuforia, afin d'acquérir
                des bases dans la création d'applications AR (réalité augmentée).
                L'objectif de ce projet était donc de m'offrir une expérience pratique et
                de me permettre de me familiariser avec les outils et les technologies
                utilisés dans le développement d'applications AR. En travaillant sur le
                projet Cars Augmented, j'ai pu apprendre les bases de la création de
                modèles 3D, de l'utilisation d'Unity pour le développement de jeux et
                d'applications, ainsi que de la création d'images cibles et de leur
                utilisation avec Vuforia. Cependant, ce projet n'ira pas plus loin, il n'y
                aura pas de mise a jour, par contre d'autres applications dans ce domaine
                verront le jour prochainement et seront disponibles sur mon github et sur
                le site
            </p>
        </section>
        <section className="elements">
            <h3>Telechargement et consultation</h3>

            <br />
            <p>
                Ce projet est open source et disponible sur mon github, vous pouvez
                telecharger la version actuelle de mon application via le lien ci-dessous
                et n'hesiter pas à me contacter pour en savoir davantages via mes
                donnéesde contact
            </p>
            <p>
                L'application est disponible via telechargement sur ce lien drive 👉
                <a href="https://docs.google.com/uc?export=download&id=1rnuOtvaGOsukhP84mIdodnYb12BAQ7Ho">
                    Telechargement
                </a>
                <br />
                <br />
                Vous pouvez tester l'application sur ce site :
                <a href="https://auto360.autosphere.fr/PixMyCar/pixmycar/media/200710/scap1157232FD-743-LM131640/" >
                    https://auto360.autosphere.fr/PixMyCar/pixmycar/media/200710/scap1157232FD-743-LM131640/
                </a>
            </p>
            <br />
            <br />
        </section>
    </div>
}

export default CarsAugmented