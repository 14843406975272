import './Cv.css'
import { motion, animate } from "framer-motion"
import { useEffect, useState } from 'react'
import React from 'react';

import flutter from '../../images/flutter.png'
import css from '../../images/css.png'
import html from '../../images/html.png'
import js from '../../images/js.png'
import mongo from '../../images/mongo.png'
import react from '../../images/react.png'
import csharp from '../../images/csharp.png'
import c from '../../images/c.png'
import php from '../../images/php.png'
import unity from '../../images/unity.png'
import android from '../../images/android_studio.png'
import firebase from '../../images/firebase.png'
import gaming from '../../images/gaming.png'
import basket from '../../images/basketBall.png'
import cinema from '../../images/cinema.png'
import yoga from '../../images/yoga.png'
import hardware from '../../images/hardware.png'
import mathSup from '../../images/mathSup.jpg'
import ECE from '../../images/ece.jpg'

import CV from '../CV/cv.pdf'

export default function Cv() {

    const dure = 0.4
    const dure2 = 0.6

    const sequence1 = [
        ["#Quoridor", { opacity: 1, y: 0 }, { duration: dure, type: 'ease' }],
        ["#Mychess", { opacity: 1, y: 0 }, { duration: dure, type: 'ease', at: '<' }],
        ["#MyDoctor", { opacity: 1, y: 0 }, { duration: dure, type: 'ease' }],
        ["#CarsAugmented", { opacity: 1, y: 0 }, { duration: dure, type: 'ease', at: '<' }],
        ["#MyDashboard", { opacity: 1, y: 0 }, { duration: dure, type: 'ease' }],
        ["#MiniSteam", { opacity: 1, y: 0 }, { duration: dure, type: 'ease', at: '<' }],
    ]

    const sequence2 = [
        [".left_section", { opacity: 1, x: 0 }, { duration: dure2, type: 'ease' }],
    ]


    useEffect(
        () => {
            animate(sequence1)
            animate(sequence2)
        }, []
    )
    return (
        <>
            <div id='container'>
                <div id="cv_container">
                    <div id='container_left'>
                        <h2 className='styled_h2'>Formation</h2>
                        <motion.div className='left_section' initial={{ opacity: 0, x: -100 }}>
                            <h4>PREPARATORY CLASS CPGE 2021</h4>
                            <h5>Math prep (MPSI-MP) Leon Mba High School, Gabon</h5>
                            <h5>Leon Mba National High School, Gabon</h5>
                            <img className='puce' src={mathSup}></img>
                        </motion.div>
                        <motion.div className='left_section' initial={{ opacity: 0, x: -100 }}>
                            <h4>ECE ENGINEERING SCHOOL 2024</h4>
                            <h5>DIGITAL ENGINEER ECE 2024</h5>
                            <h5>ECE Paris 75015, France School of Engineering.</h5>
                            <img className='puce' src={ECE}></img>
                        </motion.div>
                        <h2 className='styled_h2'>Experience</h2>
                        <motion.div className='left_section' initial={{ opacity: 0, x: -100 }}>
                            <h4>Super U Clamart victor hugo</h4>
                            <h5>Caissier dans un Super U de Clamart pendant l'été 2022, une expérience enrichissante qui m'a permis d'améliorer mon sens du relationnel</h5>
                        </motion.div>
                        <h2 className='styled_h2'>Loisirs</h2>
                        <motion.div className='left_section' initial={{ opacity: 0, x: -100 }}>
                            <div className="styled-list">
                                <div className="one">
                                    <img width={20} height={20} src={yoga} />
                                    <p>Yoga</p>
                                </div>
                                <div className="two">
                                    <img width={20} height={20} src={gaming} />
                                    <p>gaming</p>
                                </div>
                                <div className="three">
                                    <img width={20} height={20} src={basket} />
                                    <p>Basket Ball</p>
                                </div>
                                <div className="four">
                                    <img width={20} height={20} src={cinema} />
                                    <p>Cinéma</p>
                                </div>
                                <div className="five">
                                    <img width={20} height={20} src={hardware} />
                                    <p>Hardware</p>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                    <div id='container_right'>
                        <h2 className='styled_h2'>Projets</h2>
                        <div className='projets'>
                            <motion.div id='Quoridor' className='projet' initial={{ opacity: 0, y: -100 }}>
                                <h2>The Quoridor</h2>
                                <p>
                                    The Quoridor is a board game where two players compete to reach their opponent's domain despite their opponent's attempts to slow them down.
                                </p>
                                <section className='icons_langage'>
                                    <img src={c} />
                                </section>
                            </motion.div>
                            <motion.div id='MyDoctor' className='projet' initial={{ opacity: 0, y: -100 }}>
                                <h2>My doctor</h2>
                                <p>
                                    "MyDoctor" is a website I created in 2022 as part of a school project. It involved scheduling appointments with doctors based on the client's needs, whether it's a cardiologist, surgeon, pediatrician, or others.
                                </p>
                                <section className='icons_langage'>
                                    <img src={html} />
                                    <img src={php} />
                                    <img src={css} />
                                    <img src={js} />
                                </section>
                            </motion.div>
                            <motion.div id='MyDashboard' className='projet' initial={{ opacity: 0, y: -100 }}>
                                <h2>My dashboard</h2>
                                <p>
                                    "MyDashboard" is a web application I created in 2022 as part of a school project. It was a dashboard for checking Arduino projects with measurements and code related to a specific sensor.
                                </p>
                                <section className='icons_langage'>
                                    <img src={react} />
                                    <img src={mongo} />
                                </section>
                            </motion.div>
                        </div>
                        <motion.button className='button' whileHover={{ backgroundColor: 'rgb(89,198,177)', color: "rgb(15,12,16)" }}
                            transition={{ duration: 0.1, type: "ease" }} id='download_resume'><a href={CV} download>Download resume</a></motion.button>
                        <div className='projets'>
                            <motion.div id='Mychess' className='projet' initial={{ opacity: 0, y: 100 }}>
                                <h2>My chess</h2>
                                <p>
                                    "MyChess" is a web application I created in 2022 as a personal project. It's a classic chess game. The project is currently being finalized.
                                </p>
                                <section className='icons_langage'>
                                    <img src={react} />
                                </section>
                            </motion.div>
                            <motion.div id='CarsAugmented' className='projet' initial={{ opacity: 0, y: 100 }}>
                                <h2>Cars augmented</h2>
                                <p>
                                    "CarsAugmented" is a mobile augmented reality application I created in 2023 as part of a school project. The app allows you to visualize a vehicle's features through an AR camera.
                                </p>
                                <section className='icons_langage'>
                                    <img src={unity} />
                                    <img src={android} />
                                    <img src={csharp} />
                                </section>
                            </motion.div>
                            <motion.div id='MiniSteam' className='projet' initial={{ opacity: 0, y: 100 }}>
                                <h2>Mini steam</h2>
                                <p>
                                    "MiniSteam" is a mobile application I created in 2023 as part of a school project. This mobile app (Android) allows you to browse Steam games, add them to favorites, log in, register, and manage these games.
                                </p>
                                <section className='icons_langage'>
                                    <img src={flutter} />
                                    <img src={firebase} />
                                </section>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}