const sc1 = require('../Media/ScreenShotsMiniSteam/sc1.jpg');
const sc2 = require('../Media/ScreenShotsMiniSteam/sc2.jpg');
const sc3 = require('../Media/ScreenShotsMiniSteam/sc3.jpg');
const sc4 = require('../Media/ScreenShotsMiniSteam/sc4.jpg');
const sc5 = require('../Media/ScreenShotsMiniSteam/sc5.jpg');
const sc6 = require('../Media/ScreenShotsMiniSteam/sc6.jpg');
const sc7 = require('../Media/ScreenShotsMiniSteam/sc7.jpg');
const sc8 = require('../Media/ScreenShotsMiniSteam/sc8.jpg');
const sc9 = require('../Media/ScreenShotsMiniSteam/sc9.jpg');
const sc10 = require('../Media/ScreenShotsMiniSteam/sc10.jpg');
const sc11 = require('../Media/ScreenShotsMiniSteam/sc11.jpg');
const sc12 = require('../Media/ScreenShotsMiniSteam/sc12.jpg');

const MiniSteam = () => {
    return <div style={{ margin: "0 10% 0 10%" ,display:'flex',flexDirection:"column"}}>
        <h1 style={{ width: "100%", textAlign: 'center', color: "white", fontSize: '40px' }}>
            Mini steam
        </h1>
        <br></br>
        <br></br>
        <section className='elements'>
            <h3>Description du projet</h3>
            <hr></hr>
            <br></br>
            <p>MINI STEAM est une application mobile développée en solo dans le cadre d'un projet scolaire, utilisant la technologie Flutter/Dart pour sa réalisation. Cette application fonctionne sur la plateforme Android et tire parti des différentes APIs mises à disposition par Steam, leader dans la distribution numérique de jeux et autres applications sur PC, pour la gestion des données. Pour le stockage des données, Firebase (Firestore) est utilisé comme solution. L'application effectue des requêtes aux APIs de Steam pour obtenir des données, qui sont ensuite traitées et affichées en front-end. Le front-end est réalisé à l'aide de widgets dits 'stateful', permettant la création d'un système d'état pour stocker et dynamiser les widgets. Les requêtes sont des Futures qui retournent des objets JSON, lesquels sont stockés dans des listes appropriées en fonction des types de données. Diverses APIs de Steam sont utilisées, notamment pour obtenir une liste non exhaustive de jeux Steam, les jeux les plus populaires, les détails d'un jeu en fonction de son identifiant Steam, ainsi que les avis et les informations des joueurs sur un jeu. L'application démarre sur une page d'accueil affichant les jeux du moment, mais nécessite une connexion pour ajouter des jeux en favoris ou consulter ceux qui le sont déjà.</p>
        </section>
        <br></br>
        <br></br>
        <section className='elements'>
            <h3>Description detaillé et fonctionnement</h3>
            <hr></hr>
            <br></br>
            <p>
                L'application fonctionne exclusivement en réseau, effectuant des requêtes aux API de Steam pour obtenir des données qui sont ensuite traitées et renvoyées en front-end. Le front-end est géré par des widgets dits 'stateful', permettant la création d'un système d'état pour stocker et dynamiser les widgets. Les requêtes sont réalisées en utilisant des Futures qui retournent des objets JSON, lesquels sont ensuite stockés dans des listes appropriées en fonction des types de données. Sans entrer dans les détails techniques, l'application utilise les API suivantes : '<u>https://api.steampowered.com/ISteamApps/GetAppList/v2/</u>' (pour obtenir une liste non exhaustive de jeux Steam), '<u>https://api.steampowered.com/ISteamChartsService/GetMostPlayedGames/v1/?</u>' (pour obtenir les jeux les plus téléchargés sur Steam, c'est-à-dire les plus populaires), '<u>https://store.steampowered.com/api/appdetails?appids=$appId&cc=fr</u>' (cette API permet d'obtenir les détails d'un jeu en fonction de son identifiant Steam, détails qui ne sont pas disponibles dans les API précédentes, d'où son utilisation), ainsi que ces deux API '<u>https://store.steampowered.com/appreviews/$appid?json=1</u>' & '<u>https://api.steampowered.com/ISteamUser/GetPlayerSummaries/v0002/?key=**************************&steamids=$arg</u>' (ces deux API sont utilisées simultanément, où la première est utilisée pour obtenir les avis et les identifiants des joueurs sur un jeu, tandis que la deuxième retourne les informations de ces joueurs, tels que leur nom d'utilisateur et leur avatar).
                <br></br>
                <br></br>
                L'application démarre sur une page d'accueil affichant les jeux du moment, cependant, pour ajouter des jeux en favoris ou consulter ceux qui le sont déjà, une connexion utilisateur est requise.
            </p>
            <section className='screenShotList'>
                <img className="CA_img" balt='screenshot1' src={sc1} ></img>
                <img className="CA_img" alt='screenshot1' src={sc5} ></img>
                <img className="CA_img" alt='screenshot1' src={sc6} ></img>
            </section>
            <br></br>
            <br></br>
            <p>
                Bien que vous ne soyez pas connecté, vous disposez de plusieurs options dans l'application. Vous avez la possibilité de rechercher des jeux Steam, d'en voir les détails tels que le prix, les captures d'écran et les avis des utilisateurs. Vous pouvez également vous connecter si vous le souhaitez, ou vous inscrire en tant que nouvel utilisateur. Les données de connexion sont gérées par Firebase, qui propose deux services distincts : l'un pour l'authentification des utilisateurs (connexion via email et mot de passe), et l'autre pour le stockage des données (tels que les favoris de chaque utilisateur et leurs données de connexion), utilisant Firestore comme service de stockage.
            </p>
            <section className='screenShotList'>
                <img className="CA_img" alt='screenshot1' src={sc8} ></img>
                <img className="CA_img" alt='screenshot1' src={sc9} ></img>
            </section>
            <br></br>
            <br></br>
            <p>
                Lorsque nous essayons de rechercher un jeu spécifique, par exemple "Sonic Colors", qui est disponible sur Steam depuis un certain temps, l'entrée de texte dans le champ de recherche est convertie en lettres majuscules (Uppercase) et est comparée à chaque titre de jeu que nous avons stocké au démarrage de l'application, également converti en lettres majuscules (Uppercase), afin d'éviter les erreurs de reconnaissance.
            </p>

            <section className='screenShotList'>
                <img className="CA_img" alt='screenshot1' src={sc10} ></img>
                <img className="CA_img" alt='screenshot1' src={sc11} ></img>
            </section>
            <br></br>
            <br></br>
            <p>
                Une fois que vous êtes connecté, vous avez la possibilité d'ajouter un jeu en favori en utilisant un bouton présent dans la page de détails d'un jeu. Le jeu ajouté en favori est immédiatement stocké dans Firestore et ajouté à une liste de favoris propre à l'utilisateur. Dans cette même page, vous pouvez basculer entre les caractéristiques du jeu ainsi que les avis des utilisateurs, grâce à une API qui permet cette fonctionnalité. Il est également possible de filtrer les avis par langue (cette option n'est pas encore disponible dans la version actuelle, mais sera ajoutée dans la prochaine mise à jour).
            </p>

            <section className='screenShotList'>
                <img className="CA_img" alt='screenshot1' src={sc2} ></img>
                <img className="CA_img" alt='screenshot1' src={sc3} ></img>
                <img className="CA_img" alt='screenshot1' src={sc12} ></img>
            </section>
        </section>
        <br></br>
        <br></br>
        <section className='elements'>
            <h3>Perspectives et futures amélioration</h3>
            <hr></hr>
            <br></br>
            <p>
                Comme mentionner en début ce projet est un projet scolaire à la base, mais est à l'heure actuelle tres minimaliste. Il devien donc un projet personnel et comptera plusieurs amélioration comme les options suivantes :
                <ul>
                    <li>
                        La possibilité de filtrer toute les données, la recherche de jeux par genre ou par avis, la langue des avis sur les jeux, etc...
                    </li>
                    <li>
                        La possibilté d'ajouter une photo de profile, de reel parametres qui fonctionne et permettent de personaliser l'application.
                    </li>
                    <li>
                        un systeme de chat entre utilisateur style messagerie et la possibilité de partager des jeux (l'api est disponible publiquement mais ne sera pas destiné a la vente du moins avec les ressources actuelle et le probleme de license)
                    </li>
                    <li>
                        L'ajout d'une AR cameras qui permettra de voir en realité augmenté les informations de jeux et des modeles 3D relatif a une jaquette de jeux (disponible ou pas sur steam)
                    </li>
                    <li>
                        Le portage sur Iphone et d'autres platform
                    </li>
                </ul>
            </p>
        </section>
        <section className='elements'>
            <h3>Telechargement et consultation</h3>
            <hr></hr>
            <br></br>
            <p>
                Ce projet est open source et disponible sur mon github, vous pouvez telecharger la version actuelle de mon application via le lien ci-dessous et n'hesiter pas à me contacter pour en savoir davantages via mes donnéesde contact
            </p>
            <p>
                L'application est disponible via telechargement sur ce lien drive 👉 <a href='https://docs.google.com/uc?export=download&id=1LHOYj53Dn0cSjdlT98awcM1av0nfQ9cm'>Telechargement</a>
            </p>
            <br></br>
            <br></br>
        </section>
    </div>
}

export default MiniSteam