import React, { useRef } from 'react';
import { motion, animate, delay } from "framer-motion"
import { useState, useEffect } from "react"
import 'swiper/css'
import './Projects.css'
import MiniSteam from '../../components/ProjectContent/MiniSteam';
import SuperDashboard from '../../components/ProjectContent/SuperDashboard';
import CarsAugmented from '../../components/ProjectContent/CarsAugmented';
import exit from "../../images/exit.png"


export default function Projetcs() {
    const [data, setData] = useState()
    const [hideAndSick, setShow] = useState(false)
    const [project, setProject] = useState(null)
    const viewRef = useRef(null)

    const OnloadText = () => {
        var sequence = [["#titleone", { opacity: 1, x: 0 }, { duration: 0.5, type: "ease" }],
        ["#titletwo", { opacity: 1, x: 0 }, { duration: 0.5, type: "ease" }], ["#titlePP", { opacity: 1, x: 0 }, { duration: 0.5, type: "ease" }],
        ["#button_section", { opacity: 1, x: 0 }, { duration: 0.5, type: "ease" }],
        ['#langage1', { opacity: 1 }, { duration: 0.1, type: "ease" }],
        ['#langage2', { opacity: 1 }, { duration: 0.1, type: "ease" }],
        ['#langage3', { opacity: 1 }, { duration: 0.1, type: "ease" }],
        ['#langage4', { opacity: 1 }, { duration: 0.1, type: "ease" }],
        ['#langage5', { opacity: 1 }, { duration: 0.1, type: "ease" }],
        ['#langage6', { opacity: 1 }, { duration: 0.1, type: "ease" }],
        ['#langage7', { opacity: 1 }, { duration: 0.1, type: "ease" }],
        ['#langage8', { opacity: 1 }, { duration: 0.1, type: "ease" }],
        ]
        animate(sequence)
    }

    useEffect(() => {
        OnloadText()
    }, [hideAndSick])


    return (
        <>
            <div id="Projects_container">
                {
                    !hideAndSick ? <>
                        <motion.div className="section_left">
                            <section id="section_container">
                                <motion.h1 id="titleone" initial={{ opacity: 0, x: -60 }}
                                ><p id="web_style">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 554.37 137.45">
                                            <g id="Projects">
                                                <text x="0" y="100" font-family="Arial" font-size="40" fill="black">P</text>
                                                <text x="60" y="100" font-family="Arial" font-size="40" fill="black">r</text>
                                                <text x="100" y="100" font-family="Arial" font-size="40" fill="black">o</text>
                                                <text x="160" y="100" font-family="Arial" font-size="40" fill="black">j</text>
                                                <text x="190" y="100" font-family="Arial" font-size="40" fill="black">e</text>
                                                <text x="250" y="100" font-family="Arial" font-size="40" fill="black">c</text>
                                                <text x="310" y="100" font-family="Arial" font-size="40" fill="black">t</text>
                                                <text x="350" y="100" font-family="Arial" font-size="40" fill="black">s</text>
                                            </g>
                                        </svg></p></motion.h1>
                                <motion.h3 id="titletwo" initial={{ opacity: 0, x: -60 }}
                                >Hello,</motion.h3>
                                <motion.p id="titlePP" initial={{ opacity: 0, x: -60 }}
                                >
                                    On this platform, you'll discover a compilation of my projects, both academic and personal. These initiatives are mainly focused on software development, although some of them fall under the domain of the Internet of Things (IoT). Although the majority of these projects are not yet complete, they only require a few adjustments to reach completion. Some of these projects, dating back to my early days learning web programming languages and frameworks such as React.js, are dated and bear witness to my evolution.
                                    <br></br>
                                    <br></br>

                                    I'm currently creating mini-projects as well as more ambitious personal projects. Several of these are already underway and will soon be available on this platform.
                                </motion.p>
                                <section id="range1" className="langages">
                                    <motion.p id="langage1" initial={{ opacity: 0 }}>flutter</motion.p>
                                    <motion.p id="langage2" initial={{ opacity: 0 }}>html/css</motion.p>
                                    <motion.p id="langage3" initial={{ opacity: 0 }}>AWS-lambda</motion.p>
                                    <motion.p id="langage4" initial={{ opacity: 0 }}>mongoDB</motion.p>
                                </section>
                                <section id="range2" className="langages">
                                    <motion.p id="langage5" initial={{ opacity: 0 }}> node js</motion.p>
                                    <motion.p id="langage7" initial={{ opacity: 0 }}> react js</motion.p>
                                    <motion.p id="langage8" initial={{ opacity: 0 }}> next js</motion.p>
                                </section>
                            </section>
                        </motion.div>

                        <motion.div className="section_rigth">
                            <motion.div onClick={() => {
                                setShow(true)
                                setProject(<MiniSteam></MiniSteam>)
                            }} id='projects_one' transition={{ ease: "linear", duration: 1.0, delay: 0.2 }} initial={{ x: 500 }} animate={{ x: 0 }}>
                            </motion.div>
                            <motion.div onClick={() => {
                                setShow(true)
                                setProject(<SuperDashboard></SuperDashboard>)
                            }} id='projects_two' transition={{ ease: "linear", duration: 1.0, delay: 0.4 }} initial={{ x: 500 }} animate={{ x: 0 }}>
                            </motion.div>

                            <motion.div onClick={() => {
                                setShow(true)
                                setProject(<CarsAugmented></CarsAugmented>)
                            }} id='projects_three' transition={{ ease: "linear", duration: 1.0, delay: 0.6 }} initial={{ x: 500 }} animate={{ x: 0 }}>
                            </motion.div>
                        </motion.div>
                    </>:<></>
                }
                {
                    hideAndSick ? <><div id='ViewProject'>
                        <img alt='icon' src={exit} id='exitButton' onClick={()=>setShow(!hideAndSick)} />
                        {project}
                    </div></> : <></>
                }
            </div>
        </>
    )
}