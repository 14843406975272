import { Route, Router, BrowserRouter, Routes } from 'react-router-dom'
import Home from './pages/Home/Home'
import Projetcs from './pages/Projects/Projects'
import Cv from './pages/CV/CV'
import AboutMe from './pages/About/About'
import Navigation from './components/Navigation/My_navigation'

export default function App() {

  return (
    <>
      <BrowserRouter>
        <Navigation />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/projects' element={<Projetcs />} />
          <Route path='/about' element={<AboutMe />} />
          <Route path='/resume' element={<Cv />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}