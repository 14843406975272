import { useState } from 'react'
import { Link } from 'react-router-dom'
import './Navigation.css'
import { motion, animate } from 'framer-motion'
import In from '../../images/in.png'
import github from '../../images/github.png'
import mail from '../../images/mail.png'

export default function Navigation() {

    const [display_menue, setdisplay] = useState(false)

    return (
        <>
            <nav id='navigation'>
                <section id='navigation_left'>
                    <h2 id='name'>@gilbert.ntoutoume.nkoghe</h2>
                </section>
                <section id='navigation_rigth'>
                    <Link to='/' className='navItems'>home</Link>
                    <Link to='/projects' className='navItems'>projects</Link>
                    <Link to='/resume' className='navItems'>resume</Link>
                    <Link to='/about' className='navItems'>about</Link>

                    {
                        display_menue ? <div onClick={() => {
                            setdisplay(!display_menue)
                        }} id='close_menue' className='menue'>
                        </div> : <div onClick={() => {
                            setdisplay(!display_menue)
                        }} id='open_menue' className='menue'></div>
                    }
                </section>
            </nav>
            {
                display_menue ? <motion.section initial={{ x: 200 }} animate={{ x: 0 }} transition={{ duration: 0.6, type: "ease" }} id='nav_responsive'>
                    <section className='link'>
                        <Link to='/' className='navItems'>home</Link>
                        <Link to='/projects' className='navItems'>projects</Link>
                        <Link to='/resume' className='navItems'>resume</Link>
                        <Link to='/about' className='navItems'>about</Link>
                    </section>
                    <section className='social'>
                        <a href='https://github.com/gilbert86-web'><img alt='icon' src={github} width={15} height={15}></img></a>
                        <a href='https://www.linkedin.com/in/randry-claude/'><img alt='icon' src={In} width={15} height={15}></img></a>
                        <a href='mailto:gilbert.ntoutoumenkoghe@edu.ece.fr'><img alt='icon' src={mail} width={15} height={15}></img></a>
                    </section>
                </motion.section> : null
            }
        </>
    )
}