import { motion, animate } from "framer-motion"
import profilePicture from '../../images/me2.jpg'
import linkedin from '../../images/in.png'
import github from '../../images/github.png'
import mail from '../../images/mail.png'
import './About.css'
import { useEffect } from "react"

export default function AboutMe() {

    useEffect(() => {
        const sequence = [['.aboutProfilePicture', { x: 0, opacity: 1 }, { type: "ease", duration: 0.5, delay: 0.2 }],
        ['.aboutProfiledescription', { x: 0, opacity: 1 }, { type: "ease", duration: 0.5, delay: 0.2 }],
        ['.aboutContact', { x: 0, opacity: 1 }, { type: "ease", duration: 0.5 }]]
        animate(sequence)
    }, [])

    return (
        <>
            <div className="aboutMeContainer">
                <div className="aboutCard">
                    <img className="profilePicture" alt='profile picture' src={profilePicture} />
                    <motion.section initial={
                        { x: -150, opacity: 0 }
                    } className="aboutProfiledescription">
                        <p>
                            I am a passionate 22-year-old student pursuing an engineering degree at École Centrale d'Électronique (ECE). My journey led me to this prestigious school after completing a preparatory course following my high school graduation in 2019. I successfully passed the Avenir Prépa entrance exam and chose ECE from several engineering schools. My enthusiasm for electronics, web and mobile development, along with my skills in IoT and 3D modeling, are at the core of my academic journey. My goal is to combine these skills to innovate and contribute to the ever-evolving world of technology.
                        </p>
                    </motion.section>
                    <motion.section initial={
                        { x: -150, opacity: 0 }
                    } className="aboutContact">
                        <a href="https://github.com/gilbert86-web"><img alt='github' src={github}></img></a>
                        <a href="https://www.linkedin.com/in/randry-claude/"><img alt='linkedin' src={linkedin}></img></a>
                        <a href="mailto:gilbert.ntoutoumenkoghe@edu.ece.fr"><img alt='Email' src={mail}></img></a>
                    </motion.section>
                </div>
            </div>
        </>
    )
}