const DSH = require('../Media/images/dsh1.png')
const DSH1 = require('../Media/images/dsh2.png')
const DSH2 = require('../Media/images/dsh3.png')
const DSH3 = require('../Media/images/dsh4.png')
const DSH4 = require('../Media/images/dsh5.png')
const DSH5 = require('../Media/images/dsh6.png')
const DSH6 = require('../Media/images/dsh7.png')
const DSH7 = require('../Media/images/dsh8.png')
const DSH8 = require('../Media/images/dsh9.png')
const DSH9 = require('../Media/images/dsh10.jpg')
const linkedin = require('../Media/images/in.png');
const github = require('../Media/images/github.png');
const mail = require('../Media/images/mail.png');

const SuperDashboard = () => {

    return <div style={{ margin: "0 10% 0 10%" }}>
        <h1 style={{ width: "100%", textAlign: 'center', color: "white", fontSize: '40px' }}>SUPER DASHBOARD</h1>
        <br></br>
        <br></br>
        <section className='elements'>
            <h3>Description du projet</h3>
            <hr></hr>
            <br></br>
            <p>
                Dans le but de me perfectionner et de renforcer mes compétences en développement avec React JS, je me suis lancé dans un défi ambitieux : créer mon propre tableau de bord personnalisé pour la gestion de mes projets de systèmes embarqués, notamment mes projets Arduino. Ce projet, qui représente un véritable défi technique, peut également être utilisé pour d'autres types de projets.

                Le fonctionnement de ce tableau de bord repose sur deux éléments clés, adaptés spécifiquement aux besoins des systèmes embarqués, tels que l'Internet des Objets (IoT). Tout d'abord, j'ai mis en place les protocoles MQTT (Message Queuing Telemetry Transport) et WS (WebSockets) pour la transmission et la lecture des données entre le tableau de bord et les capteurs actifs. Ces protocoles offrent une communication rapide et efficace, permettant ainsi de recevoir et d'envoyer des données en temps réel entre le tableau de bord et les microcontrôleurs connectés, tels que l'ESP32, l'ESP8266 ou encore le Raspberry Pi.

                En ce qui concerne la gestion des données sur le long terme, j'ai opté pour l'utilisation d'une base de données NoSQL. Ce type de base de données offre une grande flexibilité et évolutivité, ce qui en fait un choix idéal pour stocker et gérer les données de mes projets de systèmes embarqués. L'insertion des données dans la base de données s'effectue via des API REST (Representational State Transfer) qui permettent d'assurer toutes les interactions CRUD (Create, Read, Update, Delete) avec la base de données de manière sécurisée et efficace.

                Grâce à cette architecture technique bien pensée, mon tableau de bord personnalisé me permet de gérer mes projets de systèmes embarqués de manière centralisée et efficace. Je peux visualiser les données en temps réel provenant de mes capteurs, ainsi que les stocker et les gérer sur le long terme dans ma base de données NoSQL. Ce projet m'a permis d'améliorer mes compétences en développement avec React JS, ainsi que ma compréhension des protocoles MQTT, WS et des API REST, ce qui représente une étape importante dans mon apprentissage du développement web et de la gestion de projets de systèmes embarqués.
            </p>
        </section>
        <br></br>
        <br></br>
        <section className='elements'>
            <h3>Description detaillé et fonctionnement</h3>
            <hr></hr>
            <br></br>
            <p>
                Comment fonctionne le dashboard ?
                <br></br>
                Tout d'abord, il a fallu séparer la partie web (pur front-end) de la partie serveur (pur back-end) pour une hiérarchisation maximale du projet. La partie front-end est construite sur des pages regroupant des composants de type fonctionnel, utilisant des hooks pour gérer les différents états. Pour le moment, seulement 4 pages/interactions sont disponibles dans le dashboard, que je vais vous présenter par la suite.
                <br></br>
                Lorsque l'on arrive sur le dashboard, nous avons une interface rappelant celle des écrans verrouillés de nos smartphones ou tablettes. Rien de bien impressionnant, à part les futurs éléments qui seront ajoutés dans les prochaines mises à jour.
            </p>
            <br></br>
            <section className='screenShotList_CA'>
                <img className="CA_img" alt='screenshot1' src={DSH} ></img>
            </section>
            <br></br>
            <p>
                J'ai choisi d'adopter une navigation latérale pour ce projet, comme la plupart des tableaux de bord que l'on peut trouver sur internet. Cette disposition me semble plus naturelle et ergonomique pour ce type d'interface. Actuellement, nous avons la possibilité de naviguer sur deux pages sur cinq, car les rubriques "API", "Messagerie" et "Paramètres" ne sont pas encore disponibles.
                <br></br>
                En ce qui concerne la partie gestionnaire, dès notre arrivée sur la page, nous pouvons consulter les projets enregistrés.
            </p>
            <br></br>
            <section className='screenShotList_CA'>
                <img className="CA_img" alt='screenshot1' src={DSH1} ></img>
            </section>
            <br></br>
            <p>
                Avant de pouvoir le faire, il est bien sûr nécessaire de déployer le serveur qui permettra d'accéder aux données, car sans cela, il n'y aura rien à consulter. Nous lançons donc les serveurs à partir du fichier explicitement nommé "serveur" dans notre hiérarchie de fichiers.
            </p>

            <section className='screenShotList_CA'>
                <img className="CA_img" alt='screenshot1' src={DSH6} ></img>
                <img className="CA_img" alt='screenshot1' src={DSH3} ></img>
            </section>
            <br></br>
            <br></br>
            <p>
                Une fois que les données sont disponibles, il est possible de consulter nos projets, de les modifier, de démarrer les lectures et même de les enregistrer (cette fonctionnalité n'est pas encore disponible, mais sera ajoutée ultérieurement), ainsi que d'autres fonctionnalités connexes.
                <br></br>
                Pour démarrer une lecture, nous devons interconnecter nos deux outils, à savoir le logiciel (software) et le matériel (hardware). Nous connectons notre ESP32/ESP8266/Raspberry Pi à notre PC et les mettons en réseau. Nous lançons également notre serveur MQTT et Node-RED, qui agira comme un intermédiaire pour résoudre un problème que je vous expliquerai plus tard.
            </p>
            <br></br>
            <section className='screenShotList_CA'>
                <img className="CA_img" alt='screenshot1' src={DSH9} ></img>
                <img className="CA_img" alt='screenshot1' src={DSH8} ></img>
                <img className="CA_img" alt='screenshot1' src={DSH5} ></img>
            </section>
            <br></br>
            <p>
                Lorsque j'ai connecté mes matériaux (hardware) et logiciels (software) sur le même réseau, avec mon serveur MQTT local, j'ai d'abord utilisé la bibliothèque "EspMQTTClient" pour créer un client MQTT et me connecter au serveur. Cependant, un problème s'est présenté concernant les protocoles utilisés. Pour continuer, il était essentiel d'utiliser le même type de protocole pour permettre la communication entre mon ESP8266 et mon code JavaScript (côté serveur).

                Du côté de l'Arduino, l'utilisation de websockets s'est avérée complexe à implémenter en raison du manque de documentation et des difficultés techniques rencontrées. De plus, côté React, il n'était pas possible de communiquer nativement en MQTT (mqtt://), mais plutôt en websocket (ws://), ce qui était incompatible avec l'autre côté de la communication. J'ai donc dû trouver un intermédiaire qui accepte les deux types de protocoles et me permet de créer une sorte de pont pour transmettre les données sans perte de vitesse.

                Malgré cela, la transmission des données s'est avérée extrêmement rapide, grâce à ce pont intermédiaire. La prochaine étape de mon projet consistera à permettre un fonctionnement en ligne en utilisant un broker distant, plutôt qu'un broker local. Cela me permettra de rendre mon tableau de bord utilisable à distance, ouvrant ainsi de nouvelles possibilités pour la gestion de mes projets de systèmes embarqués.
            </p>
        </section>
        <br></br>
        <br></br>
        <section className='elements'>
            <h3>Perspectives et futures amélioration</h3>
            <hr></hr>
            <br></br>
            <p>
                Concernant les points à améliorer et les futures options qui seront ajoutées, nous avons :

                <ul>
                    <li>
                        Les 3/5 des fonctionnalités qui doivent être disponibles sous peu.
                    </li>
                    <li>
                        La possibilité d'ajouter une photo de profil, d'avoir un espace personnel personnalisé pour chaque utilisateur (en termes de personnalisation, cela se retrouvera dans la rubrique "Paramètres").
                    </li>
                    <li>
                        Un système de chat entre utilisateurs, style messagerie, qui permettra de partager et discuter à propos d'un projet ou autre.
                    </li>
                    <li>
                        Un front-end plus professionnel ou au moins partiellement configurable pour le plaisir de chacun (cela rejoint le deuxième point).
                    </li>
                    <li>
                        Le portage sur iPhone et d'autres plateformes.
                    </li>
                </ul>
            </p>
        </section>
        <section className='elements'>
            <h3>Telechargement et consultation</h3>
            <hr></hr>
            <br></br>
            <p>
                Ce projet est open source et disponible sur mon github, Le code arduino ainsi qu'un tutoriel seront disponible tres prochainement
            </p>
        </section>
    </div>
}

export default SuperDashboard